import React, { useState, useEffect } from "react";
import { ethers } from "ethers";
import Header from "../Header";
import Footer from "../Footer";
import { Outlet } from 'react-router-dom';
import Section from '../Section';
import BannerSectionStyle5 from "../Section/BannerSection/BannerSectionStyle5";
import Alert from "./Alert";
import { Button, Spinner } from "react-bootstrap"; // Import Button and Spinner
function PatientProfile({ account, healthcareDAppContract, roleManagementContract }) {
  const [patientProfile, setPatientProfile] = useState(null);
  const [prescriptions, setPrescriptions] = useState([]);
  const [medicalRecords, setMedicalRecords] = useState([]);
  const [newProblem, setNewProblem] = useState("");
  const [selectedDoctor, setSelectedDoctor] = useState("");
  const [bills, setBills] = useState([]);
  const [error, setError] = useState(null);
  const [alertMessage, setAlertMessage] = useState("");
  const [alertType, setAlertType] = useState("success");
  const [doctors, setDoctors] = useState([]);
  const [authorizedDoctor, setAuthorizedDoctor] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");
  const [authorizedProviders, setAuthorizedProviders] = useState([]);
  const [pastMedicalRecords, setPastMedicalRecords] = useState([]);
  const [loadingPastRecords, setLoadingPastRecords] = useState(false);
  const [pharmacistLocations, setPharmacistLocations] = useState({});

  useEffect(() => {
    if (account) {
      fetchPharmacistLocations();
    }
  }, [account]);

  // Call fetchPatientProfile only after pharmacistLocations are fetched
  useEffect(() => {
    if (account && Object.keys(pharmacistLocations).length > 0) {
      fetchPatientProfile();
    }
  }, [account, pharmacistLocations]);

  // Function to show alert
  const showAlert = (message, type = "success") => {
    setAlertMessage(message);
    setAlertType(type);
  };

  // Fetch pharmacist locations and map them by address
  async function fetchPharmacistLocations() {
    try {
      const pharmacistData = await roleManagementContract.viewAllAuthorizedPharmacists();
      const locationMap = {};

      pharmacistData.forEach(pharmacist => {
        const [name, address, location] = pharmacist;
        locationMap[address] = { name, location };
      });

      setPharmacistLocations(locationMap);
      showAlert("Pharmacist locations fetched successfully.", "success");
    } catch (error) {
      console.error("Error fetching pharmacist locations:", error);
      showAlert("Unable to fetch pharmacist locations.", "danger");
    }
  }

  async function fetchPatientProfile() {
    try {
      const profile = await healthcareDAppContract.viewProfileNoParameters();
      const formattedProfile = {
        name: profile[0],
        number: profile[1],
        date: profile[2],
        age: profile[3].toString(),
        gender: profile[4],
        problem: profile[5],
      };

      const formattedPrescriptions = profile[6]?.map((prescription) => ({
        id: prescription[0].toString(),
        medicine: prescription[1],
        dosage: prescription[2],
        fulfilled: prescription[3] ? "Yes" : "No",
        doctorAddress: prescription[4],
        timestamp: new Date(prescription[5] * 1000).toLocaleString(),
        doctorName: prescription[6],
      })) || [];

      const formattedRecords = profile[7]?.map((record) => ({
        description: record[0],
        doctor: record[1],
        timestamp: new Date(record[2] * 1000).toLocaleString(),
        doctorAddress: record[3],
      })) || [];

      setPatientProfile(formattedProfile);
      setPrescriptions(formattedPrescriptions);
      setMedicalRecords(formattedRecords);

      const patientBills = await healthcareDAppContract.viewBills();
      const formattedBills = patientBills.map((bill) => {
        // const pharmacistInfo = pharmacistLocations[bill.pharmacistAddress] || { name: "Unknown", location: "Unknown" };

        return {
          billingId: bill.billingId.toString(),
          description: bill.description,
          medicine: bill.medicine || "", 
          dosage: bill.dosage || "",
          amount: ethers.utils.formatEther(bill.amount),
          isPaid: bill.isPaid,
          pharmacistName: bill.pharmacyName,
          location: bill.location,
        };
      });

      setBills(formattedBills);

      showAlert("Patient profile fetched successfully.", "success");
    } catch (error) {
      const errorReason = error?.data?.data?.reason || "Patient not found or access denied.";
      console.error("Error fetching patient profile:", error);
      showAlert(errorReason, "danger");
    }
  }

  

  // Fetch available doctors for updating or changing doctor
  async function fetchAvailableDoctors() {
    try {
      const doctorsList = await roleManagementContract.viewAllAuthorizedDoctors();
      const formattedDoctors = doctorsList.map((doctor) => ({
        name: doctor[0],
        specialization: doctor[2],
        address: doctor[6],
      }));
      setDoctors(formattedDoctors);
      showAlert("Available doctors fetched successfully.", "success");
    } catch (error) {
      const errorReason = error?.data?.data?.reason || "Unable to fetch doctors.";
      console.error("Error fetching doctors:", error);
      showAlert(errorReason, "danger");
    }
  }
  async function fetchPastMedicalRecords() {
    try {
      setLoadingPastRecords(true);
      const records = await healthcareDAppContract.viewPatientHistory();
      
      const formattedRecords = records[0].map((_, index) => ({
        healthProblem: records[0][index],
        medicine: records[1][index],
        dosage: records[2][index],
        fulfilled: records[3][index] ? "Yes" : "No",
        doctorName: records[4][index],
        timestamp: new Date(records[5][index] * 1000).toLocaleString()
      }));

      setPastMedicalRecords(formattedRecords);
      showAlert("Past medical records fetched successfully.", "success");
    } catch (error) {
      const errorReason = error?.data?.data?.reason || "Unable to fetch past medical records.";
      console.error("Error fetching past medical records:", error);
      showAlert(errorReason, "danger");
    } finally {
      setLoadingPastRecords(false);
    }
  }

  // Function to update the health problem and doctor
  async function updateProblemAndDoctor() {
    try {
      const tx = await healthcareDAppContract.updateProblemAndDoctor(newProblem, selectedDoctor);
      await tx.wait();
      showAlert("Health problem and doctor updated successfully!", "success");
      fetchPatientProfile();
    } catch (error) {
      const errorReason = error?.data?.data?.reason || "Error updating health problem and doctor.";
      console.error("Error updating health problem and doctor:", error);
      showAlert(errorReason, "danger");
    }
  }

  // Grant access to a doctor and refresh authorized providers list
  async function grantDoctorAccess(doctorAddress) {
    try {
      const tx = await healthcareDAppContract.grantDoctorAccess(doctorAddress);
      await tx.wait();
      showAlert(`Access successfully granted to doctor: ${doctorAddress}`, "success");
      await fetchAuthorizedProviders();
    } catch (error) {
      const errorReason = error?.data?.data?.reason || `Error granting access to doctor: ${doctorAddress}.`;
      console.error("Error granting doctor access:", error);
      showAlert(errorReason, "danger");
    }
  }

  // Revoke access from a doctor and refresh authorized providers list
  async function revokeDoctorAccess(doctorAddress) {
    try {
      const tx = await healthcareDAppContract.revokeDoctorAccess(doctorAddress);
      await tx.wait();
      showAlert("Doctor access revoked!", "success");
      await fetchAuthorizedProviders();
    } catch (error) {
      const errorReason = error?.data?.data?.reason || "Error revoking doctor access.";
      console.error("Error revoking doctor access:", error);
      showAlert(errorReason, "danger");
    }
  }

    // Fetch authorized providers
    async function fetchAuthorizedProviders() {
      try {
        const providers = await healthcareDAppContract.viewAuthorizedByMe();
        if (Array.isArray(providers) && providers.length === 3) {
          const [names, addresses, departments] = providers;
  
          const uniqueProvidersMap = new Map();
          addresses.forEach((address, index) => {
            if (!uniqueProvidersMap.has(address)) {
              uniqueProvidersMap.set(address, {
                name: names[index] || "N/A",
                address: address || "N/A",
                department: departments[index] || "N/A",
              });
            }
          });
  
          setAuthorizedProviders(Array.from(uniqueProvidersMap.values()));
          showAlert("Authorized providers fetched successfully.", "success");
        } else {
          throw new Error("Invalid data structure from contract.");
        }
      } catch (error) {
        const errorReason = error?.data?.data?.reason || "Unable to fetch authorized providers.";
        console.error("Error fetching authorized providers:", error);
        showAlert(errorReason, "danger");
      }
    }

    async function payBill(billingId, amount) {
      try {
        const tx = await healthcareDAppContract.payBill(billingId, { value: ethers.utils.parseEther(amount) });
        await tx.wait();
        showAlert("Bill paid successfully!", "success");
        fetchPatientProfile();
      } catch (error) {
        const errorReason = error?.data?.data?.reason || "Error paying bill.";
        console.error("Error paying bill:", error);
        showAlert(errorReason, "danger");
      }
    }
  
  // Filter doctors based on the search query
  const filteredDoctors = doctors.filter((doctor) =>
    doctor.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
    doctor.specialization.toLowerCase().includes(searchQuery.toLowerCase()) ||
    doctor.address.toLowerCase().includes(searchQuery.toLowerCase())
  );
  return (
    <>
      <BannerSectionStyle5
        bgUrl="/images/about/banner_bg.svg"
        imgUrl="/images/about/patient.png"
        title="Welcome to Patient Profile Page"
        subTitle="Your Partner in Health and Wellness"
      />
      <Header logoSrc="/images/logo.svg" variant="cs_heading_color" />
  
      <div className="container mt-5">
        <Alert
          type={alertType}
          message={alertMessage}
          onClose={() => setAlertMessage("")}
        />
        <Section>
          {error && <div className="alert alert-danger">{error}</div>}
  
          {/* Patient Information Section */}
          {patientProfile && (
            <div className="card shadow-sm border-primary mb-4 rounded-lg">
              <div className="card-body">
                <h4 className="card-title text-primary mb-3">Patient Information</h4>
                <div className="row">
                  <div className="col-md-6">
                    <p><strong>Name:</strong> {patientProfile.name}</p>
                    <p><strong>Number:</strong> {patientProfile.number}</p>
                    <p><strong>Date:</strong> {patientProfile.date}</p>
                  </div>
                  <div className="col-md-6">
                    <p><strong>Age:</strong> {patientProfile.age}</p>
                    <p><strong>Gender:</strong> {patientProfile.gender}</p>
                    <p><strong>Health Problem:</strong> {patientProfile.problem}</p>
                  </div>
                </div>
              </div>
            </div>
          )}
  
          {/* Past Medical Records Section */}
          <div className="card shadow-sm border-primary mb-4 rounded-lg">
            <div className="card-body">
              <h4 className="card-title text-primary mb-3">Prescriptions & Past Medical Records</h4>
              <Button onClick={fetchPastMedicalRecords} className="btn-info w-100 mb-3 rounded-pill">
                Fetch Past Medical Records
              </Button>
              {loadingPastRecords && <div className="text-center"><Spinner animation="border" variant="primary" /></div>}
              {pastMedicalRecords.length > 0 ? (
                <div className="table-responsive">
                  <table className="table table-striped table-bordered">
                    <thead className="table-dark">
                      <tr>
                        <th>Health Problem</th>
                        <th>Medicine</th>
                        <th>Dosage</th>
                        <th>Doctor</th>
                        <th>Fulfilled</th>
                        <th>Timestamp</th>
                      </tr>
                    </thead>
                    <tbody>
                      {pastMedicalRecords.map((record, index) => (
                        <tr key={index}>
                          <td>{record.healthProblem}</td>
                          <td>{record.medicine}</td>
                          <td>{record.dosage}</td>
                          <td>{record.doctorName}</td>
                          <td>{record.fulfilled}</td>
                          <td>{record.timestamp}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              ) : (
                !loadingPastRecords && <p>No past medical records / prescriptions found.</p>
              )}
            </div>
          </div>
  
          {/* Search and List Authorized Doctors Section */}
          <div className="card shadow-sm border-primary mb-4 rounded-lg">
            <div className="card-body">
              <h4 className="card-title text-primary">Search Authorized Doctors</h4>
              <input
                type="text"
                className="form-control mb-3 rounded-pill"
                placeholder="Search doctors by name, specialization, or address"
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
              />
              <Button onClick={fetchAvailableDoctors} className="btn btn-primary w-100 mb-3 rounded-pill">
                Fetch Available Doctors
              </Button>
              {filteredDoctors.length > 0 ? (
                <ul className="list-group">
                  {filteredDoctors.map((doctor, index) => (
                    <li key={index} className="list-group-item">
                      <p><strong>Name:</strong> {doctor.name}</p>
                      <p><strong>Specialization:</strong> {doctor.specialization}</p>
                      <p><strong>Address:</strong> {doctor.address}</p>
                    </li>
                  ))}
                </ul>
              ) : (
                <p>No doctors found matching the search criteria.</p>
              )}
            </div>
          </div>
  
          {/* Authorized Providers Section */}
          <div className="card shadow-sm border-primary mb-4 rounded-lg">
            <div className="card-body">
              <h4 className="card-title text-primary mb-3">Authorized Providers</h4>
              <Button onClick={fetchAuthorizedProviders} className="btn-primary w-100 mb-3 rounded-pill">
                Fetch Authorized Providers
              </Button>
              {authorizedProviders.length > 0 ? (
                <div className="table-responsive">
                  <table className="table table-striped table-bordered">
                    <thead className="table-dark">
                      <tr>
                        <th>Name</th>
                        <th>Address</th>
                        <th>Department</th>
                      </tr>
                    </thead>
                    <tbody>
                      {authorizedProviders.map((provider, index) => (
                        <tr key={index}>
                          <td>{provider.name}</td>
                          <td>{provider.address}</td>
                          <td>{provider.department}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              ) : (
                <p>No authorized providers found.</p>
              )}
            </div>
          </div>
  
          {/* Manage Doctor Access Section */}
          <div className="card shadow-sm border-primary mb-4 rounded-lg">
            <div className="card-body">
              <h4 className="card-title text-primary mb-3">Manage Doctor Access</h4>
              <select
                value={selectedDoctor}
                onChange={(e) => setSelectedDoctor(e.target.value)}
                className="form-control mb-3 rounded-pill"
              >
                <option value="">Select a Doctor</option>
                {doctors.map((doctor, index) => (
                  <option key={index} value={doctor.address}>
                    {doctor.name} - {doctor.specialization}
                  </option>
                ))}
              </select>
              <div className="d-flex">
                <Button onClick={() => grantDoctorAccess(selectedDoctor)} className="btn-primary flex-fill mr-2 rounded-pill">
                  Grant Access
                </Button>
                <Button onClick={() => revokeDoctorAccess(selectedDoctor)} className="btn-danger flex-fill rounded-pill">
                  Revoke Access
                </Button>
              </div>
            </div>
          </div>
  
          {/* Bills Section */}
          <div className="card shadow-sm border-primary mb-4 rounded-lg">
            <div className="card-body">
              <h4 className="card-title text-primary mb-3">Bills</h4>
              {bills.length > 0 ? (
                <div className="table-responsive">
                  <table className="table table-striped table-bordered">
                    <thead className="table-dark">
                      <tr>
                        <th>Bill #</th>
                        <th>Remarks</th>
                        <th>Pharmacist</th>
                        <th>Location</th>
                        <th>Amount (ETH)</th>
                        <th>Status</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {bills.map((bill, index) => (
                        <tr key={index}>
                          <td>{bill.billingId}</td>
                          <td>{bill.description}</td>
                          <td>{bill.pharmacistName}</td>
                          <td>{bill.location}</td>
                          <td>{bill.amount}</td>
                          <td>{bill.isPaid ? "Paid" : "Unpaid"}</td>
                          <td>
                            {!bill.isPaid && (
                              <Button onClick={() => payBill(bill.billingId, bill.amount)} className="btn-primary btn-sm rounded-pill">
                                Pay
                              </Button>
                            )}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              ) : (
                <p>No pending bills.</p>
              )}
            </div>
          </div>
  
          {/* Update Health Problem and Doctor Section */}
          <div className="card shadow-sm border-primary mb-4 rounded-lg">
            <div className="card-body">
              <h4 className="card-title text-primary mb-3">Update Health Problem and Doctor</h4>
              <input
                type="text"
                placeholder="New Health Problem"
                value={newProblem}
                onChange={(e) => setNewProblem(e.target.value)}
                className="form-control mb-3 rounded-pill"
              />
              <select
                value={selectedDoctor}
                onChange={(e) => setSelectedDoctor(e.target.value)}
                className="form-control mb-3 rounded-pill"
              >
                <option value="">Select a Doctor</option>
                {doctors.map((doctor, index) => (
                  <option key={index} value={doctor.address}>
                    {doctor.name} - {doctor.specialization}
                  </option>
                ))}
              </select>
              <Button onClick={updateProblemAndDoctor} className="btn-success w-100 rounded-pill">
                Update
              </Button>
            </div>
          </div>
        </Section>
      </div>
      <br />
      <Footer />
    </>
  );
}

export default PatientProfile;