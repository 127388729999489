import React, { useState, useEffect } from "react";
import { ethers } from "ethers";
import "bootstrap/dist/css/bootstrap.min.css";
import Header from "./Header";
import Footer from "./Footer";
import BannerSectionStyle5 from "./Section/BannerSection/BannerSectionStyle5";
import Alert from "./Alert";
import { pageTitle } from "../helpers/PageTitle";

function PharmacistDashboard({ account, healthcareDAppContract, roleManagementContract }) {
  pageTitle("Pharmacist Dashboard");

  const [prescriptions, setPrescriptions] = useState([]);
  const [selectedPrescriptionId, setSelectedPrescriptionId] = useState("");
  const [selectedPatientName, setSelectedPatientName] = useState("");
  const [additionalMedicines, setAdditionalMedicines] = useState("");
  const [additionalCharge, setAdditionalCharge] = useState("");
  const [pharmacyName, setPharmacyName] = useState("");
  const [location, setLocation] = useState("");
  const [alertMessage, setAlertMessage] = useState("");
  const [alertType, setAlertType] = useState("success");
  const [loading, setLoading] = useState(false); // Define loading state here

  // Fetch pharmacy details automatically based on the logged-in account address
  useEffect(() => {
    if (account) {
      fetchPharmacyDetails();
    }
  }, [account]);

  // Function to show alert
  const showAlert = (message, type = "success") => {
    setAlertMessage(message);
    setAlertType(type);
  };

  // Fetch pharmacy name and location from viewAllAuthorizedPharmacists
  async function fetchPharmacyDetails() {
    try {
      const pharmacistData = await roleManagementContract.viewAllAuthorizedPharmacists();
      const pharmacist = pharmacistData.find(([name, address, location]) => address.toLowerCase() === account.toLowerCase());

      if (pharmacist) {
        setPharmacyName(pharmacist[0]);
        setLocation(pharmacist[2]);
      } else {
        showAlert("Pharmacy details not found.", "danger");
      }
    } catch (error) {
      console.error("Error fetching pharmacy details:", error);
      showAlert("Failed to fetch pharmacy details.", "danger");
    }
  }

  // Fetch prescriptions for the selected patient name
  async function fetchPrescriptions(e) {
    e.preventDefault();
    setLoading(true); // Set loading to true when fetching prescriptions
    try {
      const prescriptionData = await healthcareDAppContract.viewPrescriptions(selectedPatientName);
      const prescriptionList = prescriptionData[1];
      const formattedPrescriptions = prescriptionList.map((prescription) => ({
        prescriptionId: ethers.BigNumber.from(prescription[0]).toString(),
        medicine: prescription[1],
        dosage: prescription[2].toString(),
        fulfilled: prescription[3] ? "Yes" : "No",
        patientAddress: prescription[4],
        timestamp: new Date(ethers.BigNumber.from(prescription[5]).toNumber() * 1000).toLocaleString(),
        doctorName: prescription[6],
      }));
      setPrescriptions(formattedPrescriptions);
      showAlert("Prescriptions fetched successfully", "success");
    } catch (err) {
      showAlert("You don't have permission to view prescriptions or the patient was not found.", "danger");
    }
    setLoading(false); // Set loading to false after fetching prescriptions
  }

  // Fulfill the selected prescription
  async function fulfillPrescription(e) {
    e.preventDefault();
    setLoading(true); // Set loading to true when fulfilling a prescription

    if (!additionalCharge || isNaN(additionalCharge) || parseFloat(additionalCharge) <= 0) {
      showAlert("Please enter a valid additional charge in ETH.", "danger");
      setLoading(false); // Set loading to false if validation fails
      return;
    }

    try {
      const additionalChargeInWei = ethers.utils.parseEther(additionalCharge);
      await healthcareDAppContract.fulfillPrescription(
        selectedPatientName,
        selectedPrescriptionId,
        additionalMedicines,
        additionalChargeInWei,
        pharmacyName,
        location
      );
      showAlert("Prescription fulfilled successfully", "success");
    } catch (err) {
      console.log(err);

      let errorReason = "You don't have permission to fulfill prescriptions or there was an issue with the transaction.";
      if (err?.data?.data?.reason) {
        errorReason = err.data.data.reason;
      } else if (err?.data?.message) {
        errorReason = err.data.message;
      }

      showAlert(errorReason, "danger");
    }
    setLoading(false); // Set loading to false after fulfilling the prescription
  }

  return (
    <>
      <BannerSectionStyle5
        bgUrl="/images/about/banner_bg.svg"
        imgUrl="/images/about/pharma.png"
        title="Welcome to Pharmacist Dashboard"
        subTitle="Your Partner in Health and Wellness"
      />
      <Header logoSrc="/images/logo.svg" variant="cs_heading_color" />
  
      <div className="container mt-5">
        {/* Alert Component */}
        <Alert
          type={alertType}
          message={alertMessage}
          onClose={() => setAlertMessage("")}
        />
  
        {/* Custom Styles */}
        <style>{`
          .form-control, .form-select, textarea {
            border-radius: 20px;
            padding: 12px 15px;
            background-color: #f7f9fc;
            border: 1px solid #ced4da;
          }
          .form-control:focus, .form-select:focus, textarea:focus {
            border-color: #007bff;
            box-shadow: 0 0 0 0.2rem rgba(0,123,255,.25);
          }
          .card {
            border-radius: 15px;
            overflow: hidden;
          }
          .btn-primary, .btn-success {
            border-radius: 20px;
            padding: 10px 20px;
          }
          .custom-outline {
            border: 1px solid #d0d7de;
          }
          .table-responsive {
            overflow-x: auto;
            max-height: 400px;
          }
          .table th, .table td {
            white-space: nowrap;
          }
        `}</style>
  
        {/* Fetch Patient Prescriptions Form */}
        <div className="card shadow-sm mb-4 border rounded custom-outline">
          <div className="card-body">
            <h5 className="card-title text-primary">Fetch Patient Prescriptions</h5>
            <form onSubmit={fetchPrescriptions}>
              <div className="mb-3">
                <label className="form-label">Enter Patient Name</label>
                <input
                  type="text"
                  value={selectedPatientName}
                  onChange={(e) => setSelectedPatientName(e.target.value)}
                  placeholder="Enter patient name"
                  className="form-control shadow-sm"
                  required
                />
              </div>
              <button type="submit" className="btn btn-primary w-100" disabled={loading}>
                {loading ? <span className="spinner-border spinner-border-sm me-2"></span> : null}
                Fetch Prescriptions
              </button>
            </form>
          </div>
        </div>
  
        {/* Prescriptions Table */}
        {prescriptions.length > 0 && (
          <div className="card shadow-sm mb-4 border rounded custom-outline">
            <div className="card-body">
              <h5 className="card-title text-primary">Prescriptions for {selectedPatientName}</h5>
              <div className="table-responsive">
                <table className="table table-striped table-hover table-bordered">
                  <thead className="table-dark">
                    <tr>
                      <th>Prescription ID</th>
                      <th>Medicine</th>
                      <th>Dosage</th>
                      <th>Fulfilled</th>
                      <th>Doctor</th>
                      <th>Timestamp</th>
                      <th>Doctor Address</th>
                    </tr>
                  </thead>
                  <tbody>
                    {prescriptions.map((prescription, index) => (
                      <tr key={index}>
                        <td>{prescription.prescriptionId}</td>
                        <td>{prescription.medicine}</td>
                        <td>{prescription.dosage}</td>
                        <td>{prescription.fulfilled}</td>
                        <td>{prescription.doctorName}</td>
                        <td>{prescription.timestamp}</td>
                        <td>{prescription.patientAddress}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        )}
  
        {/* Fulfill Prescription Form */}
        <div className="card shadow-sm mb-4 border rounded custom-outline">
          <div className="card-body">
            <h5 className="card-title text-primary">Fulfill Prescription</h5>
            <form onSubmit={fulfillPrescription}>
              <div className="mb-3">
                <label className="form-label">Select Prescription</label>
                <select
                  value={selectedPrescriptionId}
                  onChange={(e) => setSelectedPrescriptionId(e.target.value)}
                  className="form-select shadow-sm"
                  required
                >
                  <option value="">Select Prescription</option>
                  {prescriptions
                    .filter((prescription) => prescription.fulfilled === "No")
                    .map((prescription, index) => (
                      <option key={index} value={prescription.prescriptionId}>
                        {prescription.medicine} - {prescription.dosage}
                      </option>
                    ))}
                </select>
              </div>
  
              <div className="mb-3">
                <label className="form-label">Additional Medicines (if any)</label>
                <textarea
                  value={additionalMedicines}
                  onChange={(e) => setAdditionalMedicines(e.target.value)}
                  placeholder="Enter additional medicines"
                  className="form-control shadow-sm"
                  rows="3"
                />
              </div>
  
              <div className="mb-3">
                <label className="form-label">Additional Charge (in ETH)</label>
                <input
                  type="text"
                  value={additionalCharge}
                  onChange={(e) => setAdditionalCharge(e.target.value)}
                  placeholder="Enter additional charge in ETH"
                  className="form-control shadow-sm"
                  required
                />
              </div>
  
              <button type="submit" className="btn btn-success w-100" disabled={loading}>
                {loading ? <span className="spinner-border spinner-border-sm me-2"></span> : null}
                Fulfill Prescription
              </button>
            </form>
          </div>
        </div>
      </div>
      <br />
  
      <Footer />
    </>
  );
}

export default PharmacistDashboard;
