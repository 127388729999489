import React, { useState, useEffect } from "react";
import { ethers } from "ethers";
import "bootstrap/dist/css/bootstrap.min.css";
import Header from "./Header";
import Footer from "./Footer";
import BannerSectionStyle5 from "./Section/BannerSection/BannerSectionStyle5";
import Alert from "./Alert";
import { pageTitle } from "../helpers/PageTitle";

function DoctorDashboard({ account, healthcareDAppContract }) {
  pageTitle("Doctor Dashboard");

  // State variables
  const [patients, setPatients] = useState([]);
  const [selectedPatientName, setSelectedPatientName] = useState("");
  const [patientProfile, setPatientProfile] = useState(null);
  const [medicine, setMedicine] = useState("");
  const [dosage, setDosage] = useState("");
  const [billDescription, setBillDescription] = useState("");
  const [billAmount, setBillAmount] = useState("");
  const [error, setError] = useState(null);
  const [prescriptions, setPrescriptions] = useState([]);
  const [medicalRecords, setMedicalRecords] = useState([]);
  const [medicines, setMedicines] = useState([{ medicine: "", dosage: "" }]);
  const [alertMessage, setAlertMessage] = useState("");
  const [alertType, setAlertType] = useState("success");
  const [loading, setLoading] = useState(false); // Loading state to manage async operations

  useEffect(() => {
    fetchPatientsForDoctor();
  }, []);

  // Function to show alert
  const showAlert = (message, type = "success") => {
    setAlertMessage(message);
    setAlertType(type);
  };

  // Fetch all patients for this doctor
  async function fetchPatientsForDoctor() {
    try {
      if (!healthcareDAppContract) {
        showAlert("Contract not loaded", "danger");
        return;
      }
      const patientNames = await healthcareDAppContract.viewAllPatientNamesByDoctor();
      setPatients(patientNames);
    } catch (err) {
      const errorReason = err?.data?.data?.reason || "Unable to fetch patients.";
      showAlert(errorReason, "danger");
    }
  }

  // Handle change in medicine and dosage fields
  const handleMedicineChange = (index, event) => {
    const newMedicines = [...medicines];
    newMedicines[index][event.target.name] = event.target.value;
    setMedicines(newMedicines);
  };

  // Add new medicine field
  const addMedicineField = () => {
    setMedicines([...medicines, { medicine: "", dosage: "" }]);
  };

  // Remove a medicine field
  const removeMedicineField = (index) => {
    const newMedicines = medicines.filter((_, i) => i !== index);
    setMedicines(newMedicines);
  };

  // Function to issue a prescription
  const issuePrescription = async () => {
    setLoading(true); // Show loading indicator
    try {
      for (const { medicine, dosage } of medicines) {
        const tx = await healthcareDAppContract.issuePrescription(
          selectedPatientName,
          medicine,
          dosage
        );
        await tx.wait();
      }
      showAlert("Prescription issued successfully!", "success");
    } catch (err) {
      const errorReason = err?.data?.data?.reason || "Error issuing prescription.";
      showAlert(errorReason, "danger");
    }
    setLoading(false); // Hide loading indicator
  };

  // Function to view patient's profile, prescriptions, and records
  async function viewPatientProfile() {
    setLoading(true); // Show loading indicator
    try {
      const profile = await healthcareDAppContract.viewPatientProfile(selectedPatientName);

      // Format profile and associated data
      const formattedProfile = {
        patientAddress: profile[0],
        name: profile[1],
        number: profile[2],
        date: profile[3],
        age: profile[4].toString(),
        gender: profile[5],
        problem: profile[6]
      };

      const formattedPrescriptions = profile[7]?.map((prescription) => ({
        id: prescription[0].toString(),
        medicine: prescription[1],
        dosage: prescription[2],
        fulfilled: prescription[3] ? "Yes" : "No",
        doctorAddress: prescription[4],
        timestamp: new Date(prescription[5] * 1000).toLocaleString(),
        doctorName: prescription[6],
        healthProblem: prescription[7]
      })) || [];

      const formattedRecords = profile[8]?.map((record) => ({
        description: record[0],
        doctor: record[1],
        timestamp: new Date(record[2] * 1000).toLocaleString(),
        doctorAddress: record[3]
      })) || [];

      setPatientProfile(formattedProfile);
      setPrescriptions(formattedPrescriptions);
      setMedicalRecords(formattedRecords);
      setError(null);
    } catch (err) {
      const errorReason = err?.data?.data?.reason || "Error viewing patient profile.";
      showAlert(errorReason, "danger");
    }
    setLoading(false); // Hide loading indicator
  }

  // Function to generate a bill
  async function generateBill() {
    if (!billAmount || isNaN(billAmount) || parseFloat(billAmount) <= 0) {
      showAlert("Please enter a valid bill amount in ETH.", "danger");
      return;
    }

    try {
      const billAmountInEther = ethers.utils.parseEther(billAmount.toString());
      const tx = await healthcareDAppContract.generateBill(
        selectedPatientName,
        billDescription,
        billAmountInEther
      );
      await tx.wait();
      showAlert("Bill generated successfully!", "success");
    } catch (err) {
      const errorReason = err?.data?.data?.reason || "Error generating bill.";
      showAlert(errorReason, "danger");
      console.log("Error details:", err); // Log for further debugging
    }
  }

 
return (
  <>
    <BannerSectionStyle5
      bgUrl="/images/about/banner_bg.svg"
      imgUrl="/images/about/doctotdashboard.png"
      title="Welcome to Doctor Dashboard"
      subTitle="Your Partner in Health and Wellness"
    />
    <Header logoSrc="/images/logo.svg" variant="cs_heading_color" />

    <div className="container mt-5">
      {/* Alert Component */}
      <Alert
        type={alertType}
        message={alertMessage}
        onClose={() => setAlertMessage("")}
      />

      {error && <div className="alert alert-danger">{error}</div>}

      {/* Custom Styles */}
      <style>{`
        .form-control, .form-select, textarea {
          border-radius: 20px;
          padding: 12px 15px;
          background-color: #f7f9fc;
          border: 1px solid #ced4da;
        }
        .form-control:focus, .form-select:focus, textarea:focus {
          border-color: #007bff;
          box-shadow: 0 0 0 0.2rem rgba(0,123,255,.25);
        }
        .card {
          border-radius: 15px;
          overflow: hidden;
        }
        .btn-primary, .btn-success, .btn-danger {
          border-radius: 20px;
          padding: 10px 20px;
        }
        .table-responsive {
          overflow-x: auto;
          max-height: 400px;
        }
        .table th, .table td {
          white-space: nowrap;
        }
      `}</style>

      {/* Select a Patient */}
      <div className="card shadow-sm mb-4 border rounded custom-outline">
        <div className="card-body">
          <h5 className="card-title text-primary">Select a Patient</h5>
          <select
            value={selectedPatientName}
            onChange={(e) => setSelectedPatientName(e.target.value)}
            className="form-select shadow-sm mb-3"
          >
            <option value="">Select a Patient</option>
            {patients.map((patient, index) => (
              <option key={index} value={patient}>
                {patient}
              </option>
            ))}
          </select>
          <button
            onClick={viewPatientProfile}
            className="btn btn-primary w-100"
            disabled={!selectedPatientName}
          >
            {loading && <span className="spinner-border spinner-border-sm me-2"></span>}
            View Patient Profile
          </button>
        </div>
      </div>

      {/* Display Patient Profile */}
      {patientProfile && (
        <div className="card shadow-sm mb-4 border rounded custom-outline">
          <div className="card-body">
            <h5 className="card-title">Patient Profile</h5>
            <p><strong>Address:</strong> {patientProfile.patientAddress}</p>
            <p><strong>Name:</strong> {patientProfile.name}</p>
            <p><strong>Phone Number:</strong> {patientProfile.number}</p>
            <p><strong>Registration Date:</strong> {patientProfile.date}</p>
            <p><strong>Age:</strong> {patientProfile.age}</p>
            <p><strong>Gender:</strong> {patientProfile.gender}</p>
            <p><strong>Present Health Problem:</strong> {patientProfile.problem}</p>

            {/* Display Prescriptions */}
            <h5 className="mt-4">Prescriptions</h5>
            {prescriptions.length > 0 ? (
              <div>
                <div className="table-responsive">
                  <table className="table table-striped table-hover table-bordered">
                    <thead className="table-dark">
                      <tr>
                        <th>Health Problem</th>
                        <th>Medicine</th>
                        <th>Dosage</th>
                        <th>Doctor</th>
                        <th>Fulfilled</th>
                        <th>Timestamp</th>
                      </tr>
                    </thead>
                    <tbody>
                      {prescriptions.map((prescription, index) => (
                        <tr key={index}>
                          <td>{prescription.healthProblem}</td>
                          <td>{prescription.medicine}</td>
                          <td>{prescription.dosage}</td>
                          <td>{prescription.doctorName}</td>
                          <td>{prescription.fulfilled}</td>
                          <td>{prescription.timestamp}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>

                {/* Card layout for smaller screens */}
                <div className="d-block d-md-none">
                  {prescriptions.map((prescription, index) => (
                    <div className="card mb-3" key={index}>
                      <div className="card-body">
                        <h5 className="card-title text-light">Health Problem: {prescription.healthProblem}</h5>
                        <p className="card-text text-light">Medicine: {prescription.medicine}</p>
                        <p className="card-text text-light">Dosage: {prescription.dosage}</p>
                        <p className="card-text text-light">Doctor: {prescription.doctorName}</p>
                        <p className="card-text text-light">Fulfilled: {prescription.fulfilled}</p>
                        <p className="card-text text-light">Timestamp: {prescription.timestamp}</p>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            ) : (
              <p>No prescriptions issued yet.</p>
            )}
          </div>
        </div>
      )}

      {/* Issue Prescription */}
      <div className="card shadow-sm mb-4 border rounded custom-outline">
        <div className="card-body">
          <h5 className="card-title text-primary">Issue Prescription</h5>
          {medicines.map((medicineEntry, index) => (
            <div key={index} className="row mb-3 align-items-end">
              <div className="col-md-8">
                <label className="form-label">Medicine</label>
                <textarea
                  name="medicine"
                  value={medicineEntry.medicine}
                  onChange={(e) => handleMedicineChange(index, e)}
                  placeholder="Enter all medicines with same dosage"
                  className="form-control shadow-sm"
                  rows="3"
                />
              </div>
              <div className="col-md-2">
                <label className="form-label">Dosage</label>
                <input
                  type="text"
                  name="dosage"
                  value={medicineEntry.dosage}
                  onChange={(e) => handleMedicineChange(index, e)}
                  placeholder="Enter dosage"
                  className="form-control shadow-sm m-2"
                />
              </div>
              <div className="col-md-2">
                {index === medicines.length - 1 ? (
                  <button onClick={addMedicineField} className="btn btn-primary w-100">
                    +
                  </button>
                ) : (
                  <button onClick={() => removeMedicineField(index)} className="btn btn-danger w-100">
                    -
                  </button>
                )}
              </div>
            </div>
          ))}
          <button onClick={issuePrescription} className="btn btn-success w-100">
            {loading && <span className="spinner-border spinner-border-sm me-2"></span>}
            Issue Prescription
          </button>
        </div>
      </div>
    </div>

    <br />
    <Footer />
  </>
);
}

export default DoctorDashboard;
